<template>
	<div class="section-15 sect-mobile">
		<div class="container-section-mobile">
			<div class="title">Pe ce se focalizează oamenii tăi azi?</div>
			<div class="desc">În fiecare zi, colegii tăi își vor stabili promisiunea zilei în TBF Digital. Mai precis, răspund la întrebarea: Care este cel mai important lucru pe care îl voi realiza azi?</div>
			<div class="svg-animated"><svg-1-section-15 /></div>
		</div>
	</div>
</template>

<script>
	import Svg1Section15 from '../../assets/landing/mobile/Svg01Section15'

	export default {
		components: {
			Svg1Section15
		},
		mounted() {
			
		}
	}
</script>